.pac-container:after{visibility: hidden!important;position: absolute; top: -9999px; 
  left: -9999px;opacity: 0;width: 0; height: 0;}

  
  input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #1A1D1F inset !important;
    -webkit-text-fill-color: rgb(255, 255, 255) !important;
}

input::-webkit-file-upload-button {
  display: none;
}
::-webkit-scrollbar{
  display: none;
}

.carList::-webkit-scrollbar-thumb { 
  background-color: #1495eb; 
  border-radius: 20px; 
  height: 2px !important;
  width: 2px !important;
  
  }
.carList::-webkit-scrollbar { 
   background: #656566;
   height: 10px; 
   border-radius: 10px;
   padding: 10px;
   display: block;
   }

   .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
}