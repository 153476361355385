@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Helvetica;
    font-weight: 800;
    src: url(Assets/fonts/Helvetica-Font/Helvetica.ttf) format("ttf");
  }
  @font-face {
    font-family: PlayFair;
    font-weight : 900;
    src: url(Assets/fonts/PlayFair/mm.ttf) format("ttf");
  }
}

