thead th:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

thead th:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

tbody {
    margin-top: 10px;
}

/* tbody tr {
    border: 1px solid red ;
} */

.statusActive{
    background-color: #26292a;
    padding: 0 5px;
    border-radius: 3px;
    color: rgb(71, 210, 71);
}

.statusOffline{
    background-color: #26292a;
    padding: 0 5px;
    border-radius: 3.5px;
    color: rgb(221, 73, 73);
}

.alignCenter{
    align-items: center;
}

#btnSvgHolder svg{
    height: 30px;
    width: 30px;
    margin-right: 5px;
}

.bookingFilterBtn path{
    fill: white;
}

.filter>svg{
    height: 40px;
    width: 40px;
    background-color: #26292a;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}

#filterMenuItem:hover path{
    fill: black
}